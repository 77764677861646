import React from 'react'

export const ModalContext = React.createContext(
    {
        paymentsModal:
        {
            isOpen: false,
            returnUrl: 'dashboard'
        },

        paymentsModalType: {
            purpose: 0
        },

        phoneModal: { isOpen: false, returnUrl: 'dashboard' },
        shareLinkModal: { isOpen: false, link: null, currentLocation: null, prevLocation: null },
        loader: { isOpen: false },
        eventModal: { isOpen: false, returnUrl: 'dashboard', event: null },
        filtersModal: { isOpen: false, returnUrl: 'dashboard', filters: [], filtered: false, filteredData: [], filterFields:[] },
        orderModal: { isOpen: false, order: null },

        createGroupModal: { isOpen: false , returnUrl: 'dashboard'},
        createTypeModal: { isOpen: false , returnUrl: 'dashboard'},
        createOrgUnitModal: { isOpen: false , returnUrl: 'dashboard'},
        createCompany: { isOpen: false , returnUrl: 'dashboard'},
        createStaffModal: { isOpen: false , returnUrl: 'dashboard'},
        createPriceSettingsModal: { isOpen: false , returnUrl: 'dashboard'},
        createReportModal: { isOpen: false, returnUrl: 'settings/reports' },
        createOrderModal: { isOpen: false, model: null, returnUrl: 'dashboard' },

        showEventModal: () => {},
        hideEventModal: () => {},
        showModalCreateGroup: () => {},
        hideModalCreateGroup: () => { },
        showModalCreateType: () => { },
        hideModalCreateType: () => { },
        showModalCreateOrgUnit: () => { },
        hideModalCreateOrgUnit: () => { },
        showModalCreateCompany: () => { },
        hideModalCreateCompany: () => { },
        showModalCreateStaff: () => { },
        hideModalCreateStaff: () => { },
        showModalCreateService: () => { },
        hideModalCreateService: () => { },
        showModalCreatePriceSettings: () => { },
        hideModalCreatePriceSettings: () => { },
        showOrderModal: async () => await {},
        hideOrderModal: () => {},
        showModalCreateReport: () => { },
        hideModalCreateReport: () => { },

        showCreateOrderDialog: () => { },
        hideCreateOrderDialog: () => { },

        showPaymentModal: () => {},
        hidePaymentModal: () => {},
        showPhoneModal: () => {},
        hidePhoneModal: () => {},
        showSteamModal: () => {},
        hideSteamModal: () => {},
        showFiltersModal: () => {},
        hideFiltersModal: () => {},
        showShareLinkModal: () => {},
        hideShareLinkModal: () => {},
        setShareLinkModalLink: () => {},
        showLoader: () => {},
        hideLoader: () => {}
    }
);
