//Staff management service, i.e. edit/add/delete reset pw by admin etc.

//import config from 'config';
import {get, post, remove} from "../_methods/download"


export const servicesService = {
    getServices
    , createService
    , editService
    , removeService
    , getOutsourcedServices
};

async function getServices(companyId, orgUnitId) {
  companyId = companyId == undefined ? '' : companyId;
  orgUnitId = orgUnitId == undefined ? '' : orgUnitId;
  return await post(`/api/service/list?companyId=${companyId}&orgUnitId=${orgUnitId}`, true);
}

async function getOutsourcedServices(companyId, orgUnitId) {
  companyId = companyId == undefined ? '' : companyId;
  orgUnitId = orgUnitId == undefined ? '' : orgUnitId;
  return await post(`/api/service/getOutsourcedServices?companyId=${companyId}&orgUnitId=${orgUnitId}`, true);
}

async function createService(model) {
  return await post(`/api/service/add`, true, model);
}

async function editService(id, model) {
  return await post(`/api/service/edit/${id}`, true, model);
}

async function removeService(id) {
  return await remove(`/api/service/delete/${id}`, true);
}

export default servicesService;
