//import config from 'config';
import {get, post, remove} from "../_methods/download"
const editModel = { "excludedPeriods": [{ "time": "00:00", "nextDay": false }, { "time": "00:00", "nextDay": true }], "startDate": "2020-04-03T14:15:54.288Z", "endDate": "2020-04-04T14:15:54.291Z", "startTime": "00:00", "endTime": "21:00", "nextDay": true, "period": 15 };
export const schedulesService = {
    getSchedule
    , getSchedules
    , createSchedule
    , updateSchedule
    , removeSchedule
};

async function getSchedule(id) {
  //return editModel;
    return await get(`/api/schedule/${id}`, true);
}

async function getSchedules(model) {
    return await post(`/api/schedule/list`, true, JSON.stringify(model));
}

async function createSchedule(model) {
    return await post(`/api/schedule/add`, true, model);
}

async function updateSchedule(id, model) {
    return await post(`/api/schedule/edit/${id}`, true, model);
}

async function removeSchedule(id) {
    return await remove(`/api/schedule/delete/${id}`, true);
}

export default schedulesService;
