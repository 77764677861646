//import config from 'config';
import {get, post, remove} from "../_methods/download"

const rentalPointsData = [
  {
    "id": 1,
    "name": "Точка1", //enum
    "description": "Описание 1",
  }, {
    "id": 2,
    "name": "Точка2", //enum
    "description": "Описание 2",
  }, {
    "id": 3,
    "name": "Точка3", //enum
    "description": "Описание 3",
  }, {
    "id": 4,
    "name": "Точка4", //enum
    "description": "Описание 4",
  }, {
    "id": 5,
    "name": "Точка5", //enum
    "description": "Описание 5",
  }
];

var filteredData = [
  {
    "id": 3,
    "name": "Точка3", //enum
    "description": "Описание 3",
  }, {
    "id": 4,
    "name": "Точка4", //enum
    "description": "Описание 4",
  }
];


export const rentalPointsService = {
    getRentalPoints
    , createOrgUnit
    , editOrgUnit
    , removeOrgUnit
    , getFilteredRentalPoints
};

async function getFilteredRentalPoints(model) {
    return await post(`/api/orgunit/list`, true, model);
}

async function getRentalPoints(companyId, orgUnitId) {
    companyId = companyId == undefined ? '' : companyId;
    orgUnitId = orgUnitId == undefined ? '' : orgUnitId;
    return await post(`/api/orgunit/list?companyId=${companyId}&orgUnitId=${orgUnitId}`, true);
}

async function createOrgUnit(model) {
    return await post("/api/orgunit/add", true, model);
}

async function editOrgUnit(id, model) {
    return await post(`/api/orgunit/edit/${id}`, true, model);
}

async function removeOrgUnit(id) {
    await remove(`/api/orgunit/delete/${id}`, true);
}

export default rentalPointsService;
