import auth from "../_services/authentication.service";
import { Fetch } from "../_helpers/handle-response"

export const get = async (url, isAuth, body, allowAnonymous = false) => {
  const requestOptions = {
    method: 'GET',
    body: body,
    headers: body === undefined ? { } : { 'Content-Type': 'application/json' },
  };
  try {
    const response = await Fetch(url, requestOptions, isAuth, allowAnonymous);
    var result = await response.json();
    result = result.error != null ? result.error : result;
    return result;
  } catch (err) {
    //alert("Error", err);
  }
};

export const remove = async (url, isAuth, allowAnonymous = false) => {
  const requestOptions = {
    method: 'DELETE'
  };
  try {
    const response = await Fetch(url, requestOptions, isAuth, allowAnonymous);
    var result = await response.json();
    result = result.error != null ? result.error : result;
    return result;
  } catch (err) {
    alert("Error", err);
  }
};

export const post = async(url, isAuth, body, allowAnonymous = false, redirectUrl = null) => {
    const requestOptions = {
        method: 'POST',
        headers: body === undefined ? {} : { 'Accept':'application/json','Content-Type': 'application/json' },
        body: body
    };

    const response = await Fetch(url, requestOptions, isAuth, allowAnonymous).then(

      async function (response) {
          if (response && response.headers && response.headers.get("content-type") === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
              response.blob()
                .then((blob) => {
                  
                  // 2. Create blob link to download
                  const url = window.URL.createObjectURL(new Blob([blob],
                    { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' }));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `export.xlsx`);
                  // 3. Append to html page
                  document.body.appendChild(link);
                  // 4. Force download
                  link.click();
                  // 5. Clean up and remove the link
                  link.parentNode.removeChild(link);
                })
                .catch((error) => {
                  alert(error);
                });

              return null;
            }

            if (response && response.code !== 422) {
                var result = await response.json();
              return result;
            }

            if (response && response.status !== 200 && response.status !== 201) {
                  alert('Looks like there was a problem. Status Code: ' +
                      response.status);
                  return;
            }

            if (response && response.ok && redirectUrl != null) {
                window.location.href = redirectUrl;
            }

            if (response) {
              var result = await response.json();
              result = result.error != null && result.data != null ? result.data : result;
              return result;
            }
      }
    ).then(response => {

        if (response && response.data === 400) {
            alert('Error : ' + response.error.message);
        }

        if (response && response.exception) {
            return { error: { errors: [{ message: response.message }]}}
        }

        return response;

    }).catch(function (err) {
      alert(err);
    });

    return response;
}

export const postFile = async(url, isAuth, body, allowAnonymous = false, redirectUrl = null) => {

    const requestOptions = {
        method: 'POST',
        //headers: body === undefined ? {  } : { },
        body: body
    };

    const response = await Fetch(url, requestOptions, isAuth, allowAnonymous).then(

        function (response) {

            if (response.status !== 200) {
                alert('Looks like there was a problem. Status Code: ' +
                    response.status);
                return;
            }

            if (response.ok && redirectUrl != null) {
                window.location.href = redirectUrl;
            }

            return response.json();

        }

    ).then(response => {

        if (response.data === 400) {
            alert('Error : ' + response.error.message);
        }

        return response;

    }).catch(function (err) {
        alert('Error', err);
    });

    return response;
}
