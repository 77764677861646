// ReSharper disable InconsistentNaming
import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect} from 'react-router-dom';
import './App.scss';
import { auth, userService} from "./_services";
import {  ModalContext } from "./_services/modal.service";

import { withI18n } from 'react-i18next';
import { createBrowserHistory } from 'history';
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';

// ReSharper disable once UnknownCssClass
const loading = () => <div className="animated fadeIn pt-3 text-center"><div className='sk-spinner spinner-r1'></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const TemplateLayout = React.lazy(() => import("./containers/TemplateLayout"));
const Login = React.lazy(() => import('./views/Pages/Login'));
const ForgotPassword = React.lazy(() => import('./views/Pages/RestorePassword/ForgotPassword'));
const RestorePassword = React.lazy(() => import('./views/Pages/RestorePassword/RestorePassword'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
//const Templates = React.lazy(() => import('./views/Templates/Templates'));

const history = createBrowserHistory();

class App extends Component {
// ReSharper restore InconsistentNaming
  
  constructor(props) {
    super(props);

      this.showPaymentModal = (returnUrl, purpose) => {
          this.setState({
              paymentsModal: { isOpen: true, returnUrl: returnUrl },
              paymentsModalType:
              {
                  purpose: purpose
              }
          });
      };

      this.hidePaymentModal = () => {
          this.setState({ paymentsModal: { isOpen: false } });
      };

      this.showPhoneModal = (phone) => {
          this.setState({
              phoneModal: { isOpen: true, returnUrl: 'dashboard' }
          });
      };

      this.hidePhoneModal = () => {
          this.setState({ phoneModal: { isOpen: false } });
      };

      this.showEventModal = (event) => {
        this.setState({
          eventModal: { isOpen: true, returnUrl: 'dashboard', event: event }
        });
      };

      this.hideEventModal = () => {
        this.setState({ eventModal: { isOpen: false, event: null } });
      };

      this.showOrderModal = async(data) => {
        await this.setState({
            orderModal: { isOpen: true, order: data }
        });
      };

      this.hideOrderModal = () => {
        this.setState({ orderModal: { isOpen: false, order: null } });
      };

      this.showFiltersModal = (filters, data) => {
        this.setState({
            filtersModal: { isOpen: true, returnUrl: 'dashboard', filters: filters, filtered: false, filteredData: data, filterFields: []  }
        });
      };

      this.hideFiltersModal = () => {
          this.setState({ filtersModal: { isOpen: false, filters: [], filteredData: null, filterFields: [] }});
      };

      this.showLoader = () => {
          this.setState({ loader: { isOpen: true } });
      };

      this.hideLoader = () => {
          this.setState({ loader: { isOpen: false } });
      };

      this.showModalCreateGroup = (model) => {
          this.setState({ createGroupModal: { isOpen: true, model: model } });
      };

      this.hideModalCreateGroup = () => {
          this.setState({ createGroupModal: { isOpen: false } });
      };

      this.showModalCreateType = (model) => {
        this.setState({ createTypeModal: { isOpen: true, model: model } });
      };

      this.hideModalCreateType = () => {
        this.setState({ createTypeModal: { isOpen: false } });
      };

      this.showModalCreateOrgUnit = (model) => {
        this.setState({ createOrgUnitModal: { isOpen: true, model: model } });
      };

      this.hideModalCreateOrgUnit = () => {
        this.setState({ createOrgUnitModal: { isOpen: false } });
      };

      this.showModalCreateCompany = (model) => {
        this.setState({ createCompanyModal: { isOpen: true, model: model } });
      };

      this.hideModalCreateCompany = () => {
        this.setState({ createCompanyModal: { isOpen: false } });
      };

      this.showModalCreateStaff = (model) => {
        this.setState({ createStaffModal: { isOpen: true, model: model } });
      };

      this.hideModalCreateStaff = () => {
        this.setState({ createStaffModal: { isOpen: false } });
      };

      this.showModalCreateService = (model) => {
        this.setState({ createServiceModal: { isOpen: true, model: model } });
      };

      this.hideModalCreateService = () => {
        this.setState({ createServiceModal: { isOpen: false } });
      };

      this.showModalCreateReport = (model) => {
        this.setState({ createReportModal: { isOpen: true, model: model } });
      };

      this.hideModalCreateReport = () => {
        this.setState({ createReportModal: { isOpen: false } });
      };

      this.showModalCreatePriceSettings = (model) => {
        this.setState({ createServiceModal: { isOpen: true, model: model } });
      };

      this.hideModalCreatePriceSettings = () => {
        this.setState({ createPriceSettingsModal: { isOpen: false } });
      };

      this.showCreateOrderDialog = (model) => {
        this.setState({ createOrderModal: { isOpen: true, model: model } });
      };

      this.hideCreateOrderDialog = () => {
        this.setState({ createOrderModal: { isOpen: false, model: null } });
      };
      
      this.state = {
          currentUser: null,
          paymentsModal:
          {
              isOpen: false,
              returnUrl: 'dashboard'
          },
          paymentsModalType: {
              purpose: 0
          },
          confirmationModal: { isOpen: false, confirmation: '' },
          phoneModal: { isOpen: false, returnUrl: 'dashboard' },
          steamModal: { isOpen: false, returnUrl: 'dashboard' },
          filtersModal: { isOpen: false, returnUrl: 'dashboard', filters: [], filtered: false, filteredData: [], filterFields: [] },
          eventModal: { isOpen: false, returnUrl: 'dashboard', event: null },
          orderModal: { isOpen: false, order: null },
          shareLinkModal: { isOpen: false, link: null, currentLocation: null, prevLocation: null },
          loader: { isOpen: false },
          createGroupModal: {isOpen: false},
          createTypeModal: {isOpen: false},
          createOrgUnitModal: {isOpen: false},
          createCompanyModal: {isOpen: false},
          createStaffModal: {isOpen: false},
          createServiceModal: {isOpen: false},
          createPriceSettingsModal: {isOpen: false},
          createReportModal: { isOpen: false },
          createOrderModal: { isOpen: false, model: null, returnUrl: 'dashboard' },

          showPaymentModal: this.showPaymentModal,
          hidePaymentModal: this.hidePaymentModal,
          showPhoneModal: this.showPhoneModal,
          hidePhoneModal: this.hidePhoneModal,
          showEventModal: this.showEventModal,
          hideEventModal: this.hideEventModal,
          showOrderModal: this.showOrderModal,
          hideOrderModal: this.hideOrderModal,
          showFiltersModal: this.showFiltersModal,
          hideFiltersModal: this.hideFiltersModal,
          showLoader: this.showLoader,
          hideLoader: this.hideLoader,

          showCreateOrderDialog: this.showCreateOrderDialog,
          hideCreateOrderDialog: this.hideCreateOrderDialog,


          showModalCreateGroup: this.showModalCreateGroup,
          hideModalCreateGroup: this.hideModalCreateGroup,
          showModalCreateType: this.showModalCreateType,
          hideModalCreateType: this.hideModalCreateType,
          showModalCreateOrgUnit: this.showModalCreateOrgUnit,
          hideModalCreateOrgUnit: this.hideModalCreateOrgUnit,
          showModalCreateCompany: this.showModalCreateCompany,
          hideModalCreateCompany: this.hideModalCreateCompany,
          showModalCreateStaff: this.showModalCreateStaff,
          hideModalCreateStaff: this.hideModalCreateStaff,
          showModalCreateService: this.showModalCreateService,
          hideModalCreateService: this.hideModalCreateService,
          showModalCreatePriceSettings: this.showModalCreatePriceSettings,
          hideModalCreatePriceSettings: this.hideModalCreatePriceSettings,
          showModalCreateReport: this.showModalCreateReport,
          hideModalCreateReport: this.hideModalCreateReport,
      };
  }

    componentDidMount() {
      auth.currentUser.subscribe(x => this.setState({ currentUser: x }));

      userService.currentUser.subscribe(user => {
          this.setState({ user });
      });

      
      history.listen(() => {
          ym('hit', window.location.protocol + "//" + window.location.host + "/" + window.location.pathname);
      });
  }
    render() {
      var needSetPassword = auth.needSetPassword();
    return (
        <div>
            <ModalContext.Provider value={this.state}>
            <YMInitializer accounts={[57046738]} />
            <HashRouter>
                    <React.Suspense fallback={loading()}>
                        <Switch>
                            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
                            <Route exact path="/forgotpassword" name="Forgot Password" render={props => <ForgotPassword {...props} />} />
                            <Route exact path="/password/restore/:token" name="Restore Password" render={props => <RestorePassword {...props}/>} />
                            <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
                            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                            <Route exact path="/templates" name="Templates" render={props => <TemplateLayout {...props} />} />
                            <Route path="/" name="Home" render={props => auth.isAuth() ? needSetPassword ? <Redirect to="/password/restore" /> : <DefaultLayout {...props} /> : <Redirect to="/login" /> } />
                        </Switch>
                    </React.Suspense>
                </HashRouter>
            </ModalContext.Provider>
      </div>
    );
  }
}

// ... definition of App class ...
export default withI18n('common')(App);
//export default App;


