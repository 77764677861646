import {get, post, remove} from "../_methods/download"

const testData = [
  {
    id: 1,
    date: "2020-10-09 23:00:00",
    client: "Вася пупкин",
    amount: 5000,
    resources: "Катер вжух1"
  }, {
    id: 2,
    date: "2020-10-09 23:00:00",
    client: "Вася Попкин",
    amount: 5000,
    resources: "Катер вжух2"
  }, {
    id: 3,
    date: "2020-10-09 23:00:00",
    client: "Вася Папкин",
    amount: 5000,
    resources: "Катер вжух3"
  }];

export const reportsService = {
    getReports
    , createReport
    , editReport
    , removeReport
    , getReport
    //, getFilteredReports
};

async function getReports(companyId, orgUnitId) {
    companyId = companyId == undefined ? '' : companyId;
    orgUnitId = orgUnitId == undefined ? '' : orgUnitId;
    //return await post(`/api/report/list?companyId=${companyId}&orgUnitId=${orgUnitId}`, true);
    return { data: testData };
}

async function createReport(model) {
  return await post("/api/reports/partnersOrders", true, model);
}

async function editReport(id, model) {
    return await post(`/api/report/edit/${id}`, true, model);
}

async function removeReport(id) {
    await remove(`/api/report/delete/${id}`, true);
}

async function getReport(id) {
  return await get(`/api/report/${id}`, true);
}

export default reportsService;
