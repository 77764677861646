//import config from 'config';
import { authHeader, Fetch } from './../_helpers';
import { BehaviorSubject } from 'rxjs';

var tournament = null;
const currentTournamentSubject = new BehaviorSubject(JSON.parse(tournament));

export const tournamentService = {
    getTournament
    , refreshTournament
    , currentTournament: currentTournamentSubject.asObservable(),
    get currentTournamentValue() { return currentTournamentSubject.value }
};

async function refreshTournament(tournamentId) {
    currentTournamentSubject.next(null);

    return await getTournament(tournamentId);
}

async function getTournament(tournamentId, isAuth = false, allowAnonymous = false) {

    if (tournamentService.currentTournamentValue != null && tournamentService.currentTournamentValue.id === tournamentId) 
        return tournamentService.currentTournamentValue;

    const requestOptions = { method: 'GET'};
    var tournamentFromApi = null;

    await Fetch(`api/tournament/get/${tournamentId}`, requestOptions, isAuth, allowAnonymous)
        .then(response => {
            if (response) return response;
            else {
                let error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .then(response => response.json())
        .then(
            response => {
                currentTournamentSubject.next(tournamentFromApi = response.error != null  ? response : response.data);
            })
        .catch(error => {
            console.log(error);
        });

    return tournamentFromApi;
}

export default tournamentService;
