//import config from 'config';
import { authHeader, Fetch } from './../_helpers';
import { BehaviorSubject } from 'rxjs';
import { get, post, remove } from "../_methods/download";
import * as _ from "lodash";

var company = null;
const currentCompanySubject = new BehaviorSubject(JSON.parse(company));

export const companiesService = {
    getCompanies
    , getCurrentCompany
    , getCompany
    , createCompany
    , editCompany
    , removeCompany
    , selectCompany
    , currentCompany: currentCompanySubject.asObservable()
    , get currentCompanyValue() { return currentCompanySubject.value }
};

async function getCompany(id) {
    var currentCompanyId = companiesService.currentCompanyValue != null ? companiesService.currentCompanyValue.id : null;
    var comp = await get(id != undefined ? `/api/company/get/${id}` : `/api/company/get/${currentCompanyId}`, true);
    return comp;
}

async function getCurrentCompany() {
    var x = true;
    var y = x;
    if (companiesService.currentCompanyValue != null) {
        return companiesService.currentCompanyValue;
    }
    var currentCompany = localStorage.getItem("currentOrgUnitData");
    if (!_.isNil(currentCompany)) {
      return JSON.parse(currentCompany);
    }

  var companyFromApi = null;

  await Fetch(`/api/company/1`, null, true, true)
    .then(response => {
      if (response) return response;
      else {
        let error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
    })
    .then(response => response.json())
    .then(
      async response => {
        currentCompanySubject.next(companyFromApi = response.data);
        localStorage.setItem('currentOrgUnitData', JSON.stringify(response.data));
      })
    .catch(error => {
      console.log(error);
    });

    return companyFromApi;
}

async function refreshCompany() {
  return await getCompany();
}

async function selectCompany(companyId, orgUnitId) {
    var orgUnit = await get(`/api/orgunit/${orgUnitId}`, true);
    currentCompanySubject.next(orgUnit);
    localStorage.setItem('currentOrgUnitData', JSON.stringify(orgUnit));
}

async function getCompanies() {
    var companies = await get(`/api/company/list`, true);
    return companies;
}

async function createCompany(model) {
  return await post("/api/company/add", true, model);
}

async function editCompany(id, model) {
  return await post(`/api/company/edit/${id}`, true, model);
}

async function removeCompany(id) {
  await remove(`/api/company/delete/${id}`, true);
}

export default companiesService;
