//import config from 'config';
import { authHeader, Fetch } from './../_helpers';
import { BehaviorSubject } from 'rxjs';
import { get, post, remove } from "../_methods/download";
import {
  certificatesService,
  unitsService,
  rentalGroupsService,
  rentalPointsService,
  rentalTypesService,
  servicesService
} from "./index";

var orders = null;
const currentOrdersSubject = new BehaviorSubject(JSON.parse(orders));
const ordersData = [
  {
    "id": 1,
    "status": 2, //enum
        "client": "Коля Пупкин",
    "phone": "+7(912) 999-99-99",
    "statusClient": 1, //enum
    "services": 1,
    "rentalTime": 4,
    "rentalFee": 3000,
    "paymentMethod": 1, //enum
    "date": "2020-03-31 13:05:21"
  }, {
    "id": 2,
    "status": 3, //enum
        "client": "Вася Пупкин",
    "phone": "+7(921) 999-99-99",
    "statusClient": 1, // enum
    "services": 2,
    "rentalTime": 5,
    "rentalFee": 3500,
    "paymentMethod": 1, //enum
    "date": "2020-03-31 13:05:21"
  }, {
    "id": 3,
    "status": 4,
        "client": "Саша Пупкин",
    "phone": "+7(939) 999-99-99",
    "statusClient": 3,
    "services": 3,
    "rentalTime": 4,
    "rentalFee": 3000,
    "paymentMethod": 1, //enum
    "date": "2020-03-31 13:05:21"
  }, {
    "id": 4,
    "status": 8,
        "client": "Вася Пупкин",
    "phone": "+7(999) 999-99-99",
    "statusClient": 1,
    "services": 8,
    "rentalTime": 4,
    "rentalFee": 3000,
    "paymentMethod": 1, //enum
    "date": "2020-03-31 13:05:21"
  }
];

var filteredData = [
  {
    "id": 1,
    "status": 4,
        "client": "Вася Пупкин",
    "phone": "+7(900) 999-99-99",
    "statusClient": 3,
    "services": 3,
    "rentalTime": 4,
    "rentalFee": 3000,
    "paymentMethod": 1, //enum
    "date": "2020-03-31 13:05:21"
  }, {
    "id": 1,
    "status": 8,
        "client": "Вася Пупкин",
    "phone": "+7(999) 999-99-99",
    "statusClient": 1,
    "services": 8,
    "rentalTime": 4,
    "rentalFee": 3000,
    "paymentMethod": 1, //enum
    "date": "2020-03-31 13:05:21"
  }
];

const filtersOptions = {
  "statusData": [
     {
       "value": 1,
       "label": "Новый"
     }, {
       "value": 2,
       "label": "Не оплачен"
     }, {
       "value": 3,
       "label": "Оплачен"
     }, {
       "value": 4,
       "label": "Отменен"
     }
  ],
  "statusClientsData": [
    {
      "value": 1,
      "label": "Новый"
    }, {
      "value": 2,
      "label": "VIP"
    }, {
      "value": 3,
      "label": "Бывалый"
    }, {
      "value": 4,
      "label": "Бедовый"
    }
  ],
  "servicesData": [
    {
      "value": 1,
      "label": "Прокат трехколесного велосипеда"
    }, {
      "value": 2,
      "label": "Прокат самолета"
    }, {
      "value": 3,
      "label": "Аренда магазина"
    }, {
      "value": 4,
      "label": "Услуги грузчиков"
    }
  ],

  "paymentsData": [
    {
      "value": 2,
      "label": "Оплата наличными"
    }, {
      "value": 1,
      "label": "Оплата картой"
    }
  ]
}

let units = [
  {
    "id": 1,
    "group": "Группа1",
    "type": "Аренда", 
    "unit": "unit1",
    "sum": 20,
    "discount": 2,
    "paymentMethod": 1,
    "paymentStatus": 2,
    "hasCertificate": true,
    "certificateCode": "1252",
    "certificateId": 1
  }, {
    "id": 2,
    "group": "Группа2",
    "type": "Аренда",
    "unit": "unit2",
    "sum": 25,
    "discount": 3,
    "paymentMethod": 1,
    "paymentStatus": 2,
    "hasCertificate": false,
    "certificateCode": "",
    "certificateId": -1
  }, {
    "id": 3,
    "group": "Группа3",
    "type": "Аренда",
    "unit": "unit3",
    "sum": 20,
    "discount": 2,
    "paymentMethod": 1,
    "paymentStatus": 2,
    "hasCertificate": false,
    "certificateCode": "",
    "certificateId": -1
  }, {
    "id": 4,
    "group": "Группа4",
    "type": "Аренда",
    "unit": "unit4",
    "sum": 20,
    "discount": 2,
    "paymentMethod": 1,
    "paymentStatus": 2,
    "hasCertificate": false,
    "certificateCode": "",
    "certificateId": -1
  }
];

let paymentMethods = [
    {
      "id": 2,
      "name": "Наличные"
    },
    {
      "id": 1,
      "name": "Карта онлайн"
    },
    {
      "id": 3,
      "name": "Сертификат"
    },
];

let statuses = [
  {
    "id": 0,
    "name": "Новый"
  },
  {
    "id": 1,
    "name": "В работе"
  },
  {
    "id": 2,
    "name": "Исполнен"
  },
  {
    "id": 3,
    "name": "Отменен"
  }
];

let order = {
    date: "12.05.2020",
    client: "Вася Пупкин / 8-800-800-8000 / Заблокирован",
    status: {id: 1, name: "Не оплачен" },
    timeFrom: "15:00",
    timeTo: "16:00",
    point: "ул. Ленина, 100",
    complex: false,
    time: "60 минут",
    cost: 5000,
    discount: 100,
    paymentMethod: 1,
    hasCertificate: true,
    certificateCode: "9521",
    certificateId: 1
};

export const ordersService = {
    getOrders
    , getPartnerOrders
    , acceptPartnerOrder
    , declinePartnerOrder
    , getOrder
    //, refreshOrders
    , getAvailableOptions
    , getFiltersOptions
    , getEvents
    , emailTicket
    , smsConfirmUrl
    , smsConfirmPrepaymentUrl
    , createOrder
    , getSchedule
    , generateOrderContacts
    , createOrderDetails
    , updateOrderDetails
    , removeOrderDetails
    , createUnit
    , updateOrder
    , updateUnit
    , getFilteredOrders
    , getFilteredPartnerOrders
    , getOrderUnits
    , removeOrderUnit
    , copyOrderUnit
    , changeStatus
    , saveContract
    , clearCertificate
    , addComment
    , getHistory
    , createBreak
    , currentOrders: currentOrdersSubject.asObservable()
    , sendSmsCard,
    get currentOrdersValue() { return currentOrdersSubject.value }
};

async function getAvailableOptions(companyId, orgUnitId) {
    var groups = await rentalGroupsService.getRentalGroups(companyId, orgUnitId);
    var resourceKinds = await rentalTypesService.getRentalTypes(companyId, orgUnitId);
    var orgUnits = await rentalPointsService.getRentalPoints(companyId);
    var data = {
        paymentMethods: paymentMethods,
        statuses: statuses,
        resourceGroups: groups.data,
        resourceKinds: resourceKinds.data,
        services: services ? services.data : services,
        orgUnits: orgUnits.data
    };

    if (companyId) {
      var services = await servicesService.getServices(companyId, orgUnitId);
      data.services = services;
    }
    return data;
    return await get(`api/orders/getAvailableOptions/`, true);
}

async function getFiltersOptions() {
    return filtersOptions;
    return await get(`api/orders/getFiltersOptions/`, true);
}

async function createOrder(model) {
    return await post(`/api/order/add`, true, model);
}

async function createBreak(model) {
    return await post(`/api/order/createBreak`, true, model);
}

async function getEvents(model) {
    return await post(`/api/order/events`, true, JSON.stringify(model));
}

async function createOrderDetails(model) {
    return await post(`/api/orderdetail/add`, true, model);
}

async function updateOrderDetails(id, model) {
  return await post(`/api/orderdetail/edit/${id}`, true, model);
}

async function getSchedule(date, orgUnitId) {
  return await post(`/api/order/getSchedule`, true, JSON.stringify({ orderDate: date, orgUnitId }));
}

async function updateOrder(id, model) {
    return await post(`/api/order/edit/${id}`, true, model);
}

async function createUnit(model) {
  await post(`api/orders/createUnit/`, true, model);
}

async function updateUnit(model) {
  await post(`api/orders/updateUnit/${model.id}`, true, model);
}

async function getFilteredOrders(model) {
  //return filteredData;
    return await post(`/api/order/list`, true, model);
}

async function getFilteredPartnerOrders(model) {
  //return filteredData;
    return await post(`/api/orderdetail/listPartnerOrders`, true, model);
}

async function getOrders(model) {
    return await post(`api/order/list`, true, JSON.stringify(model));
}

async function acceptPartnerOrder(id) {
  return await get(`api/orderdetail/acceptPartnerOrder/${id}`, true);
}

async function declinePartnerOrder(id) {
  return await get(`api/orderdetail/declinePartnerOrder/${id}`, true);
}

async function getPartnerOrders(model) {
  return await post(`api/orderdetail/listPartnerOrders`, true, JSON.stringify(model));
}

async function getOrder(id) {
  //return order;
  return await get(`/api/order/${id}`, true);
}

async function getHistory(id) {
  return await get(`/api/order/history/${id}`, true);
}

async function getOrderUnits() {
  return units;
}

async function changeStatus(id, model) {
    return await post(`/api/order/edit/${id}`, true, JSON.stringify(model));
    //return await post(`api/orders/changeStatus/${id}`, true, model);
}

async function generateOrderContacts(order) {
  order.orderDetails.forEach(async (x) => {
    await get(`/api/contract/generate/${x.id}`, true);
  });
}

async function smsConfirmUrl(order) {
  return await get(`/api/payment/smsConfirmUrl/${order.id}`, true);
}

async function smsConfirmPrepaymentUrl(orderId, amount, surcharge) {
  return await get(`/api/payment/smsConfirmUrl/${orderId}?prepayment=${surcharge ? "" : amount}&surcharge=${surcharge ? amount : ""}`, true);
}

async function downloadPdf(order) {
  return await get(`/api/ticket/generateUrl/${order.id}`, true);
}
async function emailTicket(order) {
  return await post(`/api/ticket/emailSend/${order.id}`, true);
}

async function removeOrderDetails(id) {
  await remove(`/api/orderdetail/delete/${id}`, true);
}

async function removeOrderUnit(id) {
  var newUnits = units.filter(x => x.id !== id);
  return newUnits;
}

async function clearCertificate(orderId) {
    //await post(`api/orders/clearCertificate/${orderId}`, true);
}

async function copyOrderUnit(id) {
    var maxUnitId = Math.max.apply(Math, units.map(x => { return x.id }));
    var newUnit = units.find(x => x.id == id);
    var copy = Object.assign({}, newUnit); 
    maxUnitId = ++maxUnitId;
    copy.id = maxUnitId;
    units.push(copy);
  return units;
}

async function saveContract(id, model) {
  return await post(`api/client/updateContractData/${id}`, true, JSON.stringify(model));
}

async function addComment(id, model) {
    return await post(`/api/order/addComment/${id}`, true, JSON.stringify(model));
}
async function sendSmsCard(id) {
  return await post(`/api/order/sendSmsCard/${id}`, true);
}
export default ordersService;
