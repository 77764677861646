import { BehaviorSubject } from 'rxjs';
import moment from 'moment'
import { authHeader } from './../_helpers';
import { Fetch } from './../_helpers';
import userService from "./../_services/user.service";
import { get, post } from "../_methods/download";

//localStorage.setItem('currentUser', null)
//saving token data to 

var user = localStorage.getItem('currentAuth') != 'undefined' ? localStorage.getItem('currentAuth') : null;
const currentAuthSubject = new BehaviorSubject(JSON.parse(user));

export const auth = {
    login,
    forgotPassword,
    restorePassword,
    resetStaffPassword,
    needSetPassword,
    logout,
    register,
    isAuth,
    getUser,
    getAccessToken,
    updateAccessToken,
    resetPassword,
    resetPasswordPost,
    currentUser: currentAuthSubject.asObservable(),
    get currentUserValue() { return currentAuthSubject.value }
};

function isAuth() {
    return auth.currentUser.source._value != null;
}

function needSetPassword() {
    return auth.currentUser.source._value != null && auth.currentUser.source._value.needSetPassword != null ? true : false;
}

function getAccessToken() {
    return auth.currentUserValue != null ? auth.currentUserValue.access_token : null;
}
function updateAccessToken(token, expires) {
    var user = currentAuthSubject.getValue();
    user.access_token = token;
    user.expires_in = expires;
    localStorage.setItem('currentAuth', JSON.stringify(user));
    currentAuthSubject.next(user);
}
function getUser() {
  if (auth.currentUserValue == null) {
    auth.logout();
    window.location.href = "/#/login";
    return null;
  } else {
    auth.currentUserValue.isAdmin = true;
      return auth.currentUserValue;
  }
}

async function resetPassword(id) {
    await get(`/api/password/reset`, true); //todo:drop staff password in backend and set needSetPassword = true
}

async function resetPasswordPost(model) {
  var result = await post(`/api/password/reset`, false, model); 
  if (result.error) {
    if (result.error.errorType == 2)
      return Promise.reject(result.error.errorType);
    return Promise.reject(result.error.message);
  }

  localStorage.setItem('currentAuth', JSON.stringify({
    expires_in: result.expires_in
    , expires_in_timestamp: result.expires_in_timestamp
    , access_token: result.access_token
    , needSetPassword: null
    , isAdmin: true
  }));

  //saving user account to user service variable
  currentAuthSubject.next(result);
  userService.refreshUser();
  return auth.currentUser;
}

async function resetStaffPassword(email, companyId) {
    return await post(`/api/account/resetPassword`, true, JSON.stringify({ email, companyId }));
}

async function login(model) {
    const requestOptions = {
        method: 'POST',
        headers: { "Accept": "application/json", 'Content-Type': 'application/json' },
        body: model,
        mode: 'cors'
        };
    return await window.fetch(`/api/auth/login`, requestOptions)
            .then(response => response.json()).then(response => {
                if (response.error) {
                  if (response.error.errorType == 2)
                      return Promise.reject(response.error.errorType);
                  return Promise.reject(response.error.message);
                }
        
                localStorage.setItem('currentAuth', JSON.stringify({
                    expires_in: response.expires_in
                    , expires_in_timestamp: response.expires_in_timestamp
                    //, refreshToken: response.data.refreshToken
                    , access_token: response.access_token
                    , needSetPassword: null
                    , isAdmin: true
                    //, refreshTokenExpires: response.data.refreshTokenExpires
                    //, isOnline: response.data.isOnline
                    //, lastSeenOnlineUtc: response.data.lastSeenOnlineUtc
                }));
        
                //saving user account to user service variable
                currentAuthSubject.next(response);
                userService.refreshUser();
                return auth.currentUser;
            });
}

async function forgotPassword(model) {
    var result = await post(`/api/password/email`, false, model);
    return result;
}
async function restorePassword(model) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: model
    };

    await window.fetch(`api/password/restore`, requestOptions)
        .then(response => response.json()).then(response => {
            if (!response.success) {
                return Promise.reject(response.error.message);
            }

            localStorage.setItem('currentAuth', JSON.stringify({
                expires: response.data.expires
                , id: response.data.id
                , refreshToken: response.data.refreshToken
                , token: response.data.token
                , refreshTokenExpires: response.data.refreshTokenExpires
            }));

            currentAuthSubject.next(response.data);
            window.location.href = '/#/dashboard';
        });
}
async function register(model) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };
    
    var result = await window.fetch(`http://booking-system:6565/api/auth/registration`, requestOptions)
            .then((response) => {
                if (response.status !== 200) {
                    alert('Looks like there was a problem. Status Code: ' +
                        response.status);
                    return;
                }

                if (response.ok) {
                    //TODO: add return url?
                    return response.json();
                } else {
                    //TODO: show error message
                }
            }).then( async (res) => {
                    if (!res.success) {
                        //TODO: error without message like bad code
                        var response = {
                            success: false,
                            error: (res && res.error && res.error.message ? res.error.message : "Please retry later")
                        };
                        return response;
                }
                    var resData = res.data;

                    var authObj = {
                      expires: resData.expires
                        , id: resData.id
                      , refreshToken: resData.refreshToken
                      , token: resData.token
                      , refreshTokenExpires: resData.refreshTokenExpires
                    };
                    await localStorage.setItem('currentAuth', JSON.stringify(authObj));

                    await currentAuthSubject.next(authObj);
                    userService.refreshUser();
                    return auth.currentUser;
                }
                    
             ).catch(function (err) {
                     alert('Error Register: ' + err);
             });
        return result;
}

async function logout() {
      // remove user from local storage to log user out
      localStorage.removeItem('currentAuth');
      localStorage.removeItem('currentOrgUnitData');
      localStorage.removeItem('token');
      currentAuthSubject.next(null);
      await window.fetch(`api/auth/logout`);
      window.location.href = '/#/dashboard';
}
//export async function updateOnlineStatus() {
//    var lastUpdated = localStorage.getItem("onlineStatusUpdatedUtc");
//    var needUpdate = lastUpdated == null || (moment().diff(moment(lastUpdated), 'seconds')) >= 30;
//    if (auth.isAuth() && needUpdate) {
//        var requestOptions = {
//            method: 'POST'
//        }
//        var lastUserActive = localStorage.getItem("lastUserActive");
//        var status = (moment().diff(moment(lastUserActive), 'seconds')) < 30 ? 0 : 1;
//        Fetch(`api/utility/online/${status}`, requestOptions)
//            .then(response => response.json()).then(response => {
//                if (response.success) {
//                    localStorage.setItem("onlineStatusUpdatedUtc", new Date());

//                }
//            });
//    }
//}
//export function updateLastUserActive(){
//    localStorage.setItem("lastUserActive", new Date());
//}

export default auth;
