//import config from 'config';
import { get, post, remove } from "../_methods/download";
import { unitsService } from "./units";

var filteredData = [
  {
    "id": 3,
    "rentalGroup": "Группа1", //enum
    "name": "Аренда", //enum
    "description": "Описание 3",
  }, {
    "id": 4,
    "rentalGroup": "Группа1", //enum
    "name": "Аренда", //enum
    "description": "Описание 4",
  }
];


export const rentalTypesService = {
    getRentalTypes
    , getFilteredRentalTypes
    , createType
    , editType
    , removeType
};

async function getFilteredRentalTypes(model) {
    return await post(`/api/resourcekind/list`, true, model);
}

async function getRentalTypes(companyId, orgUnitId, model) {
    companyId = companyId == undefined ? '' : companyId;
    orgUnitId = orgUnitId == undefined ? '' : orgUnitId;
    return await post(`/api/resourcekind/list?companyId=${companyId}&orgUnitId=${orgUnitId}`, true, model);
}

async function createType(model) {
    return await post("/api/resourcekind/add", true, model);
}

async function editType(id, model) {
    return await post(`/api/resourcekind/edit/${id}`, true, model);
}

async function removeType(id) {
    await remove(`/api/resourcekind/delete/${id}`, true);
}

export default rentalTypesService;
