//import config from 'config';
import { authHeader, Fetch } from './../_helpers';
import { BehaviorSubject } from 'rxjs';
import { get, post } from "../_methods/download";
import { getActiveElement } from 'formik';

var user = null;
const currentUserSubject = new BehaviorSubject(JSON.parse(user));

export const userService = {
    getCurrentUser
    , getUser
    , getUserRights
    , getPartners
    , updateUserName
    , updateUserPassword
    , refreshUser
    //, searchUser
    , searchClientByPhone
    , getStaffs
    , unblockAccount
    , blockAccount
    , createStaff
    //, editStaff
    , deleteAccountFromCompany
    , currentUser: currentUserSubject.asObservable()
    , lastUpdatedTime
    , getAlerts
    , get currentUserValue() { return currentUserSubject.value }
};

function lastUpdatedTime() {
    return userService.currentUserValue != null ? userService.currentUserValue.lastUpdatedTime : null;
}

async function updateUserName(model) {
  try {
    var response = await post(`api/account/change`, true, model);
    if (response.error)
      alert(response.error.message);
    else
      alert("Профиль успешно изменен");
    return getUser(true);
  } catch (err) {
    alert(err);
  }
}

async function updateUserPassword(model) {
  try {
    var response = await post(`api/account/changePassword`, true, model);
    if (response.error)
      alert(response.error.message);
    else
      alert("Пароль успешно изменен");
    return getUser(true);
  } catch (err) {
    alert(err);
  }
}

async function refreshUser(clear = false) {
    if (clear) {
        currentUserSubject.next(null);
    }

    return clear ? null : await getUser(true);
}

async function getCurrentUser() {
    if (userService.currentUserValue != null) {
      return userService.currentUserValue;
    }

    var userFromApi = null;

    await Fetch(`/api/account/get`, null, true, true)
        .then(response => {
            if (response) return response;
            else {
                let error = new Error(response.statusText);
                error.response = response;
                throw error;
            }
        })
        .then(response => response.json())
        .then(
            async response => {
    
                currentUserSubject.next(userFromApi = response.data);
                localStorage.setItem('currentUserData', JSON.stringify(response.data));
            })
        .catch(error => {
            console.log(error);
        });

  return userFromApi;
}

async function searchClientByPhone(phone) {
	phone = phone.replace("+","");
    var users = await get(`/api/client/searchByPhone/${phone}`, true);
    return users && users.data.length > 0 ? users.data[0] : null;
}

function matchClientToTerm(phone, value) {
    var x = phone.toLowerCase().indexOf(value.toLowerCase()) !== -1;
    var y = x;
  return (
    phone.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}

async function getUser() {
    var user = await get(`api/account/get`, true);
    var x = true;
  return user;
}

async function getUserRights(orgUnitId) {
  var rights = await get(`api/account/getUserRights?orgUnitId=${orgUnitId}`, true);
  var x = true;
return rights;
}

async function getStaffs(companyId, name) {
    if (!name) {
      name = "";
    }

    if (!companyId) {
      companyId = "";
    }

    var staffs = await post(`/api/account/list?companyId=${companyId}&name=${name}`, true);
    return staffs;
}

async function createStaff(user) {
  var staffs = await post(`/api/account/add`, true, JSON.stringify(user));
  return staffs;
}

async function blockAccount(user) {
  var staffs = await post(`/api/account/blockAccount`, true, JSON.stringify(user));
  return staffs;
}

async function unblockAccount(user) {
  var staffs = await post(`/api/account/unblockAccount`, true, JSON.stringify(user));
  return staffs;
}

async function deleteAccountFromCompany(user) {
  var staffs = await post(`/api/account/deleteAccountFromCompany`, true, JSON.stringify(user));
  return staffs;
}

async function getAlerts(orgUnitId) {
  return await get(`/api/account/getAlerts?orgUnitId=${orgUnitId.orgUnitId}`, true);
}

async function getPartners(orgUnitId) {
    orgUnitId = orgUnitId == undefined ? '' : orgUnitId;
    return await get(`/api/account/getPartners?orgUnitId=${orgUnitId}`, true);
}

export default userService;
