import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

// the translations
const resources = {
  en: {
        common: require("./translations/en/common.json")
        , navigation: require("./translations/en/navigation.json")
        , loginRegister: require("./translations/en/login_register.json")
        , profile: require("./translations/en/profile.json")
        , editUserProfile: require("./translations/en/editUserProfile.json")
        , addOrUpdateTournament: require("./translations/en/addOrUpdateTournament.json")
        , modalTournament: require("./translations/en/modalTournament.json")
        , tournament: require("./translations/en/tournament.json")
        , confirmedPlayers: require("./translations/en/confirmedPlayers.json")
        , teams: require("./translations/en/teams.json")
        , timeline: require("./translations/en/timeline.json")
        , challenges: require("./translations/en/challenges.json")
        , anticheat: require("./translations/en/anticheat.json")
        , dashboard: require("./translations/en/dashboard.json")
        , teamInvite: require("./translations/en/teamInvite.json")
  }
  , ru: {
      common: require("./translations/ru/common.json")
      , navigation: require("./translations/ru/navigation.json")
      , loginRegister: require("./translations/ru/login_register.json")
      , profile: require("./translations/ru/profile.json")
      , editUserProfile: require("./translations/ru/editUserProfile.json")
      , addOrUpdateTournament: require("./translations/ru/addOrUpdateTournament.json")
      , modalTournament: require("./translations/ru/modalTournament.json")
      , tournament: require("./translations/ru/tournament.json")
      , confirmedPlayers: require("./translations/ru/confirmedPlayers.json")
      , teams: require("./translations/ru/teams.json")
      , timeline: require("./translations/ru/timeline.json")
      , challenges: require("./translations/ru/challenges.json")
      , anticheat: require("./translations/ru/anticheat.json")
      , dashboard: require("./translations/ru/dashboard.json")
      , teamInvite: require("./translations/ru/teamInvite.json")
  }
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
      resources,
      ns: ["common", "navigation", "loginRegister", "addOrUpdateTournament", "modalTournament", "tournament", "confirmedPlayers", "anticheat", "dashboard", 'teamInvite'],
      lng: "ru",
      fallbackLng: 'ru',
      defaultNS:"common",

      // react-i18next options
      react: {
        wait: true
      },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
