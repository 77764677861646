//import config from 'config';
import { authHeader, Fetch } from './../_helpers';
import { BehaviorSubject } from 'rxjs';
import { get, post } from "../_methods/download";
import * as _ from "lodash";

var unit = null;
const currentUnitSubject = new BehaviorSubject(JSON.parse(unit));
const unitsData = [
  {
    "id": 1,
    "name": "Катер Шустрый",
  }, {
    "id": 2,
    "name": "Катер Толстый",
  }, {
    "id": 3,
    "name": "Катер Стремительный",
  }
];

const schedules = [{ "excludedPeriods": [{ "time": "00:00", "nextDay": false }, { "time": "00:00", "nextDay": true }], "startDate": "2020-04-15T14:15:54.288Z", "endDate": "2020-04-15T14:15:54.291Z", "startTime": "00:00", "endTime": "21:00", "nextDay": true, "period": 15, "cost": 4900 },
  { "excludedPeriods": [{ "time": "01:30", "nextDay": false }, { "time": "01:45", "nextDay": false }], "startDate": "2020-04-14T14:15:54.288Z", "endDate": "2020-04-14T14:15:54.291Z", "startTime": "00:00", "endTime": "21:00", "nextDay": true, "period": 15, "cost": 4900 }];

export const unitsService = {
    getUnits
    //, refreshUnits
    , getCurrentOrgUnit
    , selectOrgUnit
    , getAvailableOptions
    , getFiltersOptions
    , createUnit
    , updateUnit
    , getFilteredUnits
    , getOrgUnit
    , currentUnit: currentUnitSubject.asObservable(),
    get currentUnitValue() { return currentUnitSubject.value }
};

async function getCurrentOrgUnit() {
    if (unitsService.currentUnitValue != null) {
        return unitsService.currentUnitValue;
    }
    var currentUnit = localStorage.getItem("currentOrgUnitData");
    if (!_.isNil(currentUnit)) {
        return JSON.parse(currentUnit);
    }

  var unitFromApi = null;

  await Fetch(`/api/orgunit/1`, null, true, true)
    .then(response => {
      if (response) return response;
      else {
        let error = new Error(response.statusText);
        error.response = response;
        throw error;
      }
    })
    .then(response => response.json())
    .then(
      async response => {
        currentUnitSubject.next(unitFromApi = response.data);
        localStorage.setItem('currentOrgUnitData', JSON.stringify(response.data));
      })
    .catch(error => {
      console.log(error);
    });

  return unitFromApi;
}

async function selectOrgUnit(orgUnitId) {
  var orgUnit = await get(`/api/orgunit/${orgUnitId}`, true);
  currentUnitSubject.next(orgUnit.data);
  localStorage.setItem('currentOrgUnitData', JSON.stringify(orgUnit.data));
}

async function getOrgUnit(orgUnitId) {
  var orgUnit = await get(`/api/orgunit/${orgUnitId}`, true);
  return orgUnit;
}

async function getAvailableOptions() {
    return await get(`api/units/getAvailableOptions/`, true);
}

async function getFiltersOptions() {
    return await get(`api/units/getFiltersOptions/`, true);
}

async function createUnit(model) {
    await post(`api/units/addOrUpdate/`, true, model);
}

async function updateUnit(model) {
    await post(`api/units/addOrUpdate/`, true, model);
}

async function getFilteredUnits(model) {
  return await get(`api/units/list/`, true, model);
}

async function getUnits() {
    return await post(`/api/orgunit/list`, true);
}


export default unitsService;
