//import config from 'config';
import { authHeader, Fetch } from './../_helpers';
import { BehaviorSubject } from 'rxjs';
import {get, post} from "../_methods/download"

var audit = null;
const currentClientsSubject = new BehaviorSubject(JSON.parse(audit));
const auditData = [
  {
    "id": 1,
    "data": "2020-04-06 21:54:20", //enum
    "staff": "Вася Пупкин",
    "action": "Просмотр чего-нибудь",
    "object": "Заказ №1"
  }, {
    "id": 2,
    "data": "2020-04-06 21:54:20", //enum
    "staff": "Вася Пупкин",
    "action": "Просмотр чего-нибудь",
    "object": "Клиент №1"
  }, {
    "id": 3,
    "data": "2020-04-06 21:54:20", //enum
    "staff": "Вася Пупкин",
    "action": "Просмотр чего-нибудь",
    "object": "Сертификат №1"
  }, {
    "id": 4,
    "data": "2020-04-06 21:54:20", //enum
    "staff": "Вася Пупкин",
    "action": "Просмотр чего-нибудь",
    "object": "Заказ №2"
  }
];

var filteredData = [
  {
    "id": 2,
    "data": "2020-04-06 21:54:20", //enum
    "staff": "Вася Пупкин",
    "action": "Просмотр чего-нибудь",
    "object": "Клиент №1"
  }, {
    "id": 3,
    "data": "2020-04-06 21:54:20", //enum
    "staff": "Вася Пупкин",
    "action": "Просмотр чего-нибудь",
    "object": "Сертификат №1"
  }
];


export const auditService = {
    getAuditData
    , getFilteredAudits
    , currentClients: currentClientsSubject.asObservable(),
    get currentClientsValue() { return currentClientsSubject.value }
};

async function getFilteredAudits(model) {
  return filteredData;
  return await get(`api/audit/list/`, true, model);
}

async function getAuditData(model) {
    return auditData;
    return await get(`api/audit/list`, true, model);
}

export default auditService;
