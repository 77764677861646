//import config from 'config';
import {get, post, remove} from "../_methods/download"

const widgetsData = [
  {
    "id": 1,
    "name": "Widget1", //enum
    "description": "Описание 1",
  }, {
    "id": 2,
    "name": "Widget2", //enum
    "description": "Описание 2",
  }, {
    "id": 3,
    "name": "Widget3", //enum
    "description": "Описание 3",
  }, {
    "id": 4,
    "name": "Widget4", //enum
    "description": "Описание 4",
  }, {
    "id": 5,
    "name": "Widget5", //enum
    "description": "Описание 5",
  }
];

var filteredData = [
  {
    "id": 3,
    "name": "Widget3", //enum
    "description": "Описание 3",
  }, {
    "id": 4,
    "name": "Widget5", //enum
    "description": "Описание 4",
  }
];

export const widgetsService = {
    getWidgets
    , getWidget
    , createWidget
    , updateWidget
    , removeWidget
    , getFilteredWidgets
};

async function getWidget(id) {
  return await get(`/api/widgets/${id}}`, true);
}

async function getWidgets(model) {
  return await post(`/api/widgets/list`, true, JSON.stringify(model));
}

async function getFilteredWidgets(model) {
  return await post(`/api/widgets/list`, true, model);
}

async function createWidget(model) {
  await post(`/api/widgets/add`, true, model);
}

async function updateWidget(id, model) {
  return await post(`/api/widgets/edit/${id}`, true, model);
}

async function removeWidget(id) {
    return await remove(`/api/widgets/delete/${id}`, true);
}

export default widgetsService;
