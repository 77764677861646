import auth  from './../_services/authentication.service';
async function RefreshToken() {
  var user = auth.currentUserValue;
  var token = user.access_token;

     await fetch('/api/auth/refresh',
            {
                method: 'post',
                headers: {
                  'Content-Type': 'application/json'
                  , 'Authorization': `Bearer ${token}`
                }
            })
        .then(tokenResponse => tokenResponse.json())
         .then(tokenResponse => {
             if (tokenResponse != null && tokenResponse.access_token != null)
               auth.updateAccessToken(tokenResponse.access_token, tokenResponse.expires_in_timestamp);
        });
}

export async function Fetch(resource, init, addAuthHeader = true, queryIfNotAuthed = false) {
    if (addAuthHeader) {
        const token = auth.getAccessToken();
        if (token || queryIfNotAuthed) {
            if (init == null) {
                init = {}
            }
            if (init.headers == null) {
                init.headers = {}
            }

            if (token) {
                init.headers["Authorization"] = `Bearer ${token}`;
            }
        } else {
            window.location.href = "/#/login";
            return null;
        }
    }

    var res = await fetch(resource, init);
    var user = auth.currentUserValue;

    var y = true;

  if (res.status === 401 && user) {
        if (Date.parse(user.expires_in_timestamp) <= (Date.now() -  60*60*24*1000*2)) {
            window.location.href = "/#/login";
            return null;
        } else {
            console.log("Getting new response " + res.status);

            await RefreshToken();

            return await Fetch(resource, init, addAuthHeader, queryIfNotAuthed);
        }
    } else if (res.status === 401) {
      throw new Error("Unauthorized");
    }
    var x = true;
    return res;
}

export async function FetchAllowAnonymous(resource, init) {
  return await Fetch(resource, init, true, true);
}
