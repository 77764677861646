//Staff management service, i.e. edit/add/delete reset pw by admin etc.

//import config from 'config';
import {get} from "../_methods/download"

const staffsData = [
  {
    "id": 1,
    "name": "Сотрудник1", //enum
    "point": "Точка1",
        "description": "Описание 1",
    "needSetPassword": false
  }, {
    "id": 2,
    "name": "Сотрудник2", //enum
    "point": "Точка1",
        "description": "Описание 2",
    "needSetPassword": true
  }, {
    "id": 3,
    "name": "Сотрудник3", //enum
    "point": "Точка1",
        "description": "Описание 3",
    "needSetPassword": false
  }, {
    "id": 4,
    "name": "Сотрудник4", //enum
    "point": "Точка1",
        "description": "Описание 4",
    "needSetPassword": false
  }, {
    "id": 5,
    "name": "Сотрудник5", //enum
    "point": "Точка1",
        "description": "Описание 5",
    "needSetPassword": false
  }
];

var filteredData = [
  {
    "id": 3,
    "name": "Группа3", //enum
    "point": "Точка1",
        "description": "Описание 3",
    "needSetPassword": false
  }, {
    "id": 4,
    "name": "Группа4", //enum
    "point": "Точка1",
        "description": "Описание 4",
    "needSetPassword": false
  }
];


export const staffsService = {
    getStaffs
    , getFilteredStaffs
};

async function getFilteredStaffs(model) {
  return filteredData;
  return await get(`api/staffs/list/`, true, model);
}

async function getStaffs(model) {
    return staffsData;
    return await get(`api/staffs/list`, true, model);
}

export default staffsService;
