//import config from 'config';
import { authHeader, Fetch } from './../_helpers';
import { BehaviorSubject } from 'rxjs';
import {get, post, remove} from "../_methods/download"

var priceSettings = null;
const currentPriceSettingsSubject = new BehaviorSubject(JSON.parse(priceSettings));
const priceSettingsData = [
  {
    "id": 1,
    "name": "Вариант 1",
    "activeFromUTC": "2020-04-13 12:03:45",
    "activeTillUTC": "2020-04-14 12:03:45",
    "enabledForDays": "1,4,7",//string(через запятую номера дней недели, когда применяется схема)
    "applyingFromTime": "09:00",//int(когда начинает действовать в течение дня с 9 утра, с 11 утра и т.д.)
    "applyingTillTime": "21:00",//int(когда заканчивает действовать в течение дня с 9 утра, с 11 утра и т.д.)
    "priority": 2,
    "price": 4500,
    "overrideMinBookTime": false,
    "minBookTime": 2,
    "isPromo": false
  }, {
    "id": 2,
    "name": "Вариант 2",
    "activeFromUTC": "2020-04-14 12:03:45",
    "activeTillUTC": "2020-04-15 12:03:45",
    "enabledForDays": "1,4,7",
    "applyingFromTime": "09:00",
    "applyingTillTime": "21:00",
    "priority": 2,
    "price": 4500,
    "overrideMinBookTime": false,
    "minBookTime": 2,
    "isPromo": false
  }, {
    "id": 3,
    "name": "Вариант 3",
    "activeFromUTC": "2020-04-13 12:03:45",
    "activeTillUTC": "2020-04-16 12:03:45",
    "enabledForDays": "1,4,7",
    "applyingFromTime": "09:00",
    "applyingTillTime": "21:00",
    "priority": 2,
    "price": 4500,
    "overrideMinBookTime": false,
    "minBookTime": 2,
    "isPromo": false
  }, {
    "id": 4,
    "name": "Вариант 4",
    "activeFromUTC": "2020-04-13 12:03:45",
    "activeTillUTC": "2020-04-14 12:03:45",
    "enabledForDays": "1,4,7",
    "applyingFromTime": "09:00",
    "applyingTillTime": "21:00",
    "priority": 2,
    "price": 4500,
    "overrideMinBookTime": false,
    "minBookTime": 2,
    "isPromo": false
  }, {
    "id": 5,
    "name": "Вариант 5",
    "activeFromUTC": "2020-04-13 12:03:45",
    "activeTillUTC": "2020-04-14 12:03:45",
    "enabledForDays": "1,4,7",
    "applyingFromTime": "09:00",
    "applyingTillTime": "21:00",
    "priority": 2,
    "price": 4500,
    "overrideMinBookTime": false,
    "minBookTime": 2,
    "isPromo": false
  }
];



var filteredData = [
  {
    "id": 4,
    "name": "Вариант 4",
    "activeFromUTC": "2020-04-13 12:03:45",
    "activeTillUTC": "2020-04-14 12:03:45",
    "enabledForDays": "1,4,7",
    "applyingFromTime": "09:00",
    "applyingTillTime": "21:00",
    "priority": 2,
    "price": 4500,
    "overrideMinBookTime": false,
    "minBookTime": 2,
    "isPromo": false
  }, {
    "id": 1,
    "name": "Вариант 5",
    "activeFromUTC": "2020-04-13 12:03:45",
    "activeTillUTC": "2020-04-14 12:03:45",
    "enabledForDays": "1,4,7",
    "applyingFromTime": "09:00",
    "applyingTillTime": "21:00",
    "priority": 2,
    "price": 4500,
    "overrideMinBookTime": false,
    "minBookTime": 2,
    "isPromo": false
  }
];

const filtersOptions = {
  "statusPriceSettingsData": [
    {
      "value": 1,
      "label": "Новый"
    }, {
      "value": 2,
      "label": "VIP"
    }, {
      "value": 3,
      "label": "Бывалый"
    }, {
      "value": 4,
      "label": "Бедовый"
    }
  ]
}

export const priceSettingsService = {
    getPriceSettings
    , getSettings
    //, getAvailableOptions
    , getFiltersOptions
    , create
    , update
    , removePriceSettings
    , getFilteredPriceSettings
    , currentPriceSettings: currentPriceSettingsSubject.asObservable(),
    get currentPriceSettingsValue() { return currentPriceSettingsSubject.value }
};

//async function getAvailableOptions() {
//    return await get(`api/priceSettings/getAvailableOptions/`, true);
//}
async function getSettings(id) {
  return await get(`/api/pricesettings/${id}`, true);
}

async function getFiltersOptions() {
    return filtersOptions;
    return await get(`api/priceSettings/getFiltersOptions/`, true);
}

async function createOrder(model) {
    await post(`api/priceSettings/addOrUpdate/`, true, model);
}

async function updateOrder(model) {
    await post(`api/priceSettings/addOrUpdate/`, true, model);
}

async function getFilteredPriceSettings(model) {
  return filteredData;
  return await get(`api/priceSettings/list/`, true, model);
}

//async function refreshPriceSettings(tournamentId) {
//  currentPriceSettingsSubject.next(null);
//
//    return await getPriceSettings(tournamentId);
//}

async function getPriceSettings(type, companyId, orgUnitId) {
    companyId = companyId == undefined ? '' : companyId;
    orgUnitId = orgUnitId == undefined ? '' : orgUnitId;
    var allPriceSettingsData = companyId != '' && orgUnitId != '' ? await post(`/api/pricesettings/list?companyId=${companyId}&orgUnitId=${orgUnitId}`, true, {}) : await post(`/api/pricesettings/list`, true, {});
    var today = new Date();
    var activePriceSettingsData = allPriceSettingsData.data.filter(x => new Date(x.activeFromUTC) < today && today < new Date(x.activeTillUTC));
    switch (type) {
        case 0: return allPriceSettingsData.data;
        case 1: return activePriceSettingsData;
        default: return priceSettingsData;
    }
}

async function create(model) {
    return await post(`/api/pricesettings/add`, true, model);
}

async function update(id, model) {
  return await post(`/api/pricesettings/edit/${id}`, true, model);
}

async function removePriceSettings(id) {
    return await remove(`/api/pricesettings/delete/${id}`, true);
}

export default priceSettingsService;
