//import config from 'config';
import { get, post, remove } from "../_methods/download";
import { BehaviorSubject } from 'rxjs';

var groups = null;
const currentGroupsSubject = new BehaviorSubject(JSON.parse(groups));

var filteredData = [
  {
    "id": 3,
    "name": "Группа3", //enum
    "description": "Описание 3",
  }, {
    "id": 4,
    "name": "Группа4", //enum
    "description": "Описание 4",
  }
];


export const rentalGroupsService = {
    getRentalGroups
    , getFilteredRentalGroups
    , createGroup
    , editGroup
    , removeGroup
    , currentGroups: currentGroupsSubject.asObservable(),
};

async function getFilteredRentalGroups(model) {
    var x = true;
    var y = x;
    return await post(`/api/resourcegroup/list`, true, model);
}

async function getRentalGroups(id) {
    var data = await post(id != undefined ? `/api/resourcegroup/list?orgUnitId=${id}` : `/api/resourcegroup/list`, true);
    return data;
}

async function createGroup(model) {
    return await post("/api/resourcegroup/add", true, model);
}

async function editGroup(id, model) {
    return await post(`/api/resourcegroup/edit/${id}`, true, model);
}

async function removeGroup(id) {
  await remove(`/api/resourcegroup/delete/${id}`, true);
}

export default rentalGroupsService;
