//import config from 'config';
import { authHeader, Fetch } from './../_helpers';
import { BehaviorSubject } from 'rxjs';
import {get, post} from "../_methods/download"

var clients = null;
const currentClientsSubject = new BehaviorSubject(JSON.parse(clients));
const clientsData = [
  {
    "id": 1,
    "fio": "Вася Пупкин", //enum
    "phone": "+7(923) 999-99-99",
    "statusClient": 1, //enum
    "countOrders": 1,
    "lastOrderDate": "2020-03-31 13:05:21"
  }, {
    "id": 2,
    "fio": "Вася Пупкин", //enum
    "phone": "+7(985) 999-99-99",
    "statusClient": 2, //enum
    "countOrders": 1,
    "lastOrderDate": "2020-03-31 13:05:21"
  }, {
    "id": 3,
    "fio": "Вася Пупкин", //enum
    "phone": "+7(974) 999-99-99",
    "statusClient": 3, //enum
    "countOrders": 1,
    "lastOrderDate": "2020-03-31 13:05:21"
  }, {
    "id": 4,
    "fio": "Вася Пупкин", //enum
    "phone": "+7(992) 999-99-99",
    "statusClient": 4, //enum
    "countOrders": 1,
    "lastOrderDate": "2020-03-31 13:05:21"
  }, {
    "id": 5,
    "fio": "Вася Пупкин", //enum
    "phone": "+7(910) 999-99-99",
    "statusClient": 5, //enum
    "countOrders": 1,
    "lastOrderDate": "2020-03-31 13:05:21"
  }
];

var filteredData = [
  {
    "id": 2,
    "fio": "Вася Пупкин", //enum
    "phone": "+7(999) 999-99-99",
    "statusClient": 2, //enum
    "countOrders": 1,
    "lastOrderDate": "2020-03-31 13:05:21"
  }, {
    "id": 3,
    "fio": "Вася Пупкин", //enum
    "phone": "+7(999) 999-99-99",
    "statusClient": 3, //enum
    "countOrders": 1,
    "lastOrderDate": "2020-03-31 13:05:21"
  }
];

const filtersOptions = {
  "statusClientsData": [
    {
      "value": 1,
      "label": "Новый"
    }, {
      "value": 2,
      "label": "VIP"
    }, {
      "value": 3,
      "label": "Бывалый"
    }, {
      "value": 4,
      "label": "Бедовый"
    }
  ]
}

export const clientsService = {
    getClients
    //, refreshClients
    , getAvailableOptions
    , getFiltersOptions
    , createClient
    , updateClient
    , getFilteredClients
    , currentClients: currentClientsSubject.asObservable()
    , sendSmsCard
    , getClient,
    get currentClientsValue() { return currentClientsSubject.value }
};

async function getAvailableOptions() {
    return await get(`api/clients/getAvailableOptions/`, true);
}

async function getFiltersOptions() {
    return filtersOptions;
    return await get(`api/clients/getFiltersOptions/`, true);
}

async function createClient(model) {
   return await post(`/api/client/add`, true, model);
}

async function updateClient(id, model) {
    return await post(`api/client/edit/${id}`, true, model);
}

async function getFilteredClients(model) {
  //return filteredData;
    return await post(`api/client/list`, true, model);
}

//async function refreshClients(tournamentId) {
//  currentClientsSubject.next(null);
//
//    return await getClients(tournamentId);
//}

async function getClients(model) {
    //return clientsData;
    return await post(`api/client/list`, true, JSON.stringify(model));
}
async function getClient(id) {
    return await get(`api/client/${id}`, true);
}

async function sendSmsCard(clientId, orgUnitId) {
  return await post(`/api/client/sendSmsCard/${clientId}/${orgUnitId}`, true);
}

export default clientsService;
