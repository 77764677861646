//import config from 'config';
import { authHeader, Fetch } from './../_helpers';
import { BehaviorSubject } from 'rxjs';
import {get, post} from "../_methods/download"
import {
  unitsService,
  rentalGroupsService,
  rentalPointsService,
  rentalTypesService,
  servicesService
} from "./index";

export const certificatesService = {
    getCertificates
    , getCertificateByCode
    , getCertificate
    , changeStatus
    , createCertificate
    , updateCertificate
    , getAvailableOptions
    , sendToEmail
    , sendToSms
    , printCertificate
};

let paymentMethods = [
  {
    "id": 2,
    "name": "Наличные"
  },
  {
    "id": 1,
    "name": "Карта онлайн"
  }
];

let statuses = [
  {
    "id": 0,
    "name": "Новый"
  },
  {
    "id": 1,
    "name": "Оплачен"
  },
  {
    "id": 2,
    "name": "Использован"
  },
  {
    "id": 3,
    "name": "Отменен"
  }
];

async function getAvailableOptions(companyId, orgUnitId) {
  var groups        = await rentalGroupsService.getRentalGroups(companyId, orgUnitId);
  var resourceKinds = await rentalTypesService.getRentalTypes(companyId, orgUnitId);
  //var services = await servicesService.getServices(companyId, orgUnitId);
  var orgUnits = await rentalPointsService.getRentalPoints(companyId);
  var data = {
    paymentMethods: paymentMethods,
    statuses: statuses,
    resourceGroups: groups.data,
    resourceKinds: resourceKinds.data,
    //services: services.data,
    orgUnits: orgUnits.data
  };
  return data;
}

async function getCertificateByCode(number) {
    return await get(`/api/giftcertificate/search/${number}`, true);
}

async function getCertificates(model) {
    return await post(`api/giftcertificate/list`, true, JSON.stringify(model));
}

async function createCertificate(model) {
    return await post(`api/giftcertificate/add`, true, model);
}

async function updateCertificate(id, model) {
  return await post(`/api/giftcertificate/edit/${id}`, true, model);
}


async function getCertificate(id) {
  //return order;
  return await get(`/api/giftcertificate/${id}`, true);
}

async function changeStatus(id, model) {
  return await post(`/api/giftcertificate/edit/${id}`, true, JSON.stringify(model));
  //return await post(`api/orders/changeStatus/${id}`, true, model);
}

function matchCertificateToTerm(code, value) {
  return (
    code.toLowerCase().indexOf(value.toLowerCase()) !== -1
  );
}

async function sendToEmail(certificateId, model) {
  return await post(`/api/giftcertificate/sendToEmail/${certificateId}`, true, model);
}

async function sendToSms(certificateId, model) {
  return await get(`/api/payment/smsConfirmUrl/certificate/${certificateId}`, true);
}

async function printCertificate(certificateId) {
  var response = await post(`/api/giftcertificate/printCertificateLink/${certificateId}`, true, JSON.stringify({ printPdf: true }));
  return response.data.url;
}

export default certificatesService;
